const binding = { modules: {}, dataActions: {} };

    (binding.modules['peetsaw'] = {
        c: () => require('partner/themes/peetsaw/peetsaw.tsx'),
        $type: 'themeModule',
        da: [],
        definitionExtensions: ['active-image','b2b-requests-status','business-organization-list','buybox','cart-icon','cart','checkout-delivery-options','checkout','content-block','header','image-list','navigation-menu','order-confirmation','order-template-list','promo-banner','quickview','ratings-histogram','reviews-list','search-result-container','search','store-selector','text-block','write-review'],
        iNM: false,
        ns: '__local__',
        n: 'peetsaw',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'peetsaw.theme.settings.json',
        md: 'src/themes/peetsaw'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };